import React, { createContext, useState, useEffect } from 'react';
import commonApi from '../utils/api';

export const BranchContext = createContext();

export function BranchContextProvider({ children }) {
    const [addModal, setAddModal] = useState(false);
    const [allData, setAllData] = useState([]);
    const [allBranch, setAllBranch] = useState([]);
    const [viewData, setViewData] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [alertMesaage, setAlertMesaage] = useState('');
    const [severity, setSeverity] = useState('');

    const onCloseAlert = () => {
        setOpenAlert(false);
        setAlertMesaage('');
        setSeverity('')
    };

    const handleAddModal = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setViewData('')
        setIsEdit(false);
        setAddModal(false);
    };

    const onLoadApi = () => {
        setFirstLoading(false);
        commonApi.post('/courier/list')
            .then((response) => {
                setFirstLoading(true);
                if (response?.data?.data) {
                    setAllBranch(response?.data?.data);
                    setAllData(response?.data?.data);
                }
            })
            .catch((error) => {
                setFirstLoading(true);
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        onLoadApi();
    }, []);

    // You can provide any values or functions you want to make available to consumers here
    const contextValue = {
        isEdit,
        onCloseAlert,
        severity,
        alertMesaage,
        openAlert,
        onLoadApi,
        isLoading,
        firstLoading,
        viewData,
        allBranch,
        allData,
        handleAddModal,
        handleCloseModal,
        setAddModal,
        addModal,
        setAllData,
        setAllBranch,
        setViewData,
        setFirstLoading,
        setIsLoading,
        setSeverity,
        setAlertMesaage,
        setOpenAlert,
        setIsEdit,
    };

    // Render the provider with children
    return <BranchContext.Provider value={contextValue}>{children}</BranchContext.Provider>;
}
