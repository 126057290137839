import React, { createContext, useState, useEffect } from 'react';
import commonApi from '../utils/api';
import { useNavigate, useLocation } from 'react-router-dom';

export const CommonContext = createContext();

export function CommonContextProvider({ children }) {
    const [addModal, setAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [merchant, setMerchant] = useState([]);
    const [merchantDetails, setMerchantDetails] = useState([]);
    const [wareHouse, setWareHouse] = useState([]);
    const [permission, setPermission] = useState({});
    const [courier, setCourier] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [alertMesaage, setAlertMesaage] = useState('');
    const [globalSearch, setGlobalSearch] = useState('');
    const [severity, setSeverity] = useState('');
    const [user, setUser] = useState(null);
    const [userPermission, setUserPermission] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const onCloseAlert = () => {
        setOpenAlert(false);
        setAlertMesaage('');
        setSeverity('')
    };
    const handleSearchSubmit = (onLoadApi) => {
        navigate('/orders')
        if (location.pathname === '/orders') {
            setIsSearch(true);
        }
    };
    const storedUserData = localStorage.getItem('user');

    const handleAddModals = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
    };
    const commonMerchant = () => {
        commonApi.post('merchant/listForDropDown')
            .then((response) => {
                if (response?.data?.data) {
                    setMerchant(response?.data?.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };
    const allPermissions = () => {
        commonApi.post('users/permissions', { admin_id: user?.id })
            .then((response) => {
                if (response?.data?.data) {
                    setPermission(response?.data?.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };
    const commonCourier = () => {
        commonApi.post('courier/listForDropDown')
            .then((response) => {
                if (response?.data?.data) {
                    setCourier(response?.data?.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };
    const commonWareHouse = () => {
        let postReq = {};
        if (user?.user_type === "Merchant") {
            postReq = { merchant_id: user?.id }
        }
        commonApi.post('merchant-warehouse/listForDropDown', postReq)
            .then((response) => {
                if (response?.data?.data) {
                    setWareHouse(response?.data?.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };
    const onMerchantDetails = (id) => {
        let postReq = {};
        if (user?.user_type === "Merchant") {
            postReq = { merchant_id: user?.id }
        }else{
            postReq = { merchant_id: id }
        }
        commonApi.post('merchant/merchant-details', postReq)
            .then((response) => {
                if (response?.data?.data) {
                    setMerchantDetails(response?.data?.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        if (user) {
            commonWareHouse();
            commonCourier();
            allPermissions();
        }
    }, [user])
    useEffect(() => {
        if (storedUserData) {
            const parsedUser = JSON.parse(storedUserData);
            if (parsedUser?.permissions) {
                setUserPermission(JSON.parse(parsedUser?.permissions))
            }
            setUser(parsedUser);
            if (parsedUser?.user_type !== 'Merchant') {
                commonMerchant();
            }
            // if (parsedUser?.user_type === 'Merchant' && location.pathname === '/') {
            //     navigate('/');
            // }
            console.log('parsedUser', parsedUser)
        }
        if (!storedUserData && location.pathname === '/admin') {
            navigate('/admin');
        } else if (!storedUserData) {
            navigate('/login');
        }
    }, [storedUserData]);
    // You can provide any values or functions you want to make available to consumers here
    const contextValue = {
        onMerchantDetails,
        isSearch,
        handleSearchSubmit,
        permission,
        courier,
        globalSearch,
        user,
        userPermission,
        onCloseAlert,
        severity,
        alertMesaage,
        openAlert,
        commonMerchant,
        wareHouse,
        merchantDetails,
        merchant,
        isLoading,
        handleAddModals,
        handleCloseModal,
        setAddModal,
        addModal,
        setIsLoading,
        setMerchant,
        setMerchantDetails,
        setWareHouse,
        setSeverity,
        setAlertMesaage,
        setOpenAlert,
        setUser,
        setUserPermission,
        setGlobalSearch,
        setCourier,
        setPermission,
        setIsSearch
    };

    // Render the provider with children
    return <CommonContext.Provider value={contextValue}>{children}</CommonContext.Provider>;
}
