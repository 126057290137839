import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utils/api';
import { CommonContext } from './CommonContext';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

export const PickupContext = createContext();

export function PickupContextProvider({ children }) {
    const { user } = useContext(CommonContext);
    const [addModal, setAddModal] = useState(false);
    const [allData, setAllData] = useState([]);
    const [allPickupRequest, setAllPickupRequest] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const [viewData, setViewData] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMesaage, setAlertMesaage] = useState('');
    const [severity, setSeverity] = useState('');
    const [pickupTime, setPickupTime] = useState();
    const [pickupDate, setPickupDate] = useState();
    const [locationState, setLocationState] = useState(null);
    const location = useLocation();


    const onCloseAlert = () => {
        setOpenAlert(false);
        setAlertMesaage('');
        setSeverity('')
    };

    const handleAddModal = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setViewData('');
        setIsEdit(false);
        setAddModal(false);
    };
    console.log('location', location)
    const onLoadApi = () => {
        let postReq = {};
        if (user?.user_type === "Merchant") {
            postReq = { merchant_id: user?.id }
        } else if (location?.state) {
            postReq = { merchant_id: location?.state?.id }
            setLocationState(location.state)
        }
        setFirstLoading(false);
        commonApi.post('pickup-requests/list', postReq)
            .then((response) => {
                setFirstLoading(true);
                if (response?.data?.data) {
                    setAllPickupRequest(response?.data?.data);
                    setAllData(response?.data?.data);
                }
            })
            .catch((error) => {
                setFirstLoading(true);
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        onLoadApi();
    }, []);


    // You can provide any values or functions you want to make available to consumers here
    const contextValue = {
        pickupTime,
        pickupDate,
        onCloseAlert,
        severity,
        alertMesaage,
        openAlert,
        isEdit,
        onLoadApi,
        viewData,
        isLoading,
        firstLoading,
        allPickupRequest,
        allData,
        handleAddModal,
        handleCloseModal,
        setAddModal,
        addModal,
        setAllData,
        setAllPickupRequest,
        setFirstLoading,
        setIsLoading,
        setViewData,
        setIsEdit,
        setSeverity,
        setAlertMesaage,
        setOpenAlert,
        setPickupTime,
        setPickupDate,
    };

    // Render the provider with children
    return <PickupContext.Provider value={contextValue}>{children}</PickupContext.Provider>;
}
