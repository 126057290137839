import axios from 'axios';

// Create axios instance
const api = axios.create({
    baseURL: 'https://b2bconnect.gaintlogistic.com/v1',
    timeout: 50000,
    headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json'
    },
});

// Log the token to verify it is retrieved correctly
console.log('localStorage token:', localStorage.getItem('userToke'));
console.log('localStorage userType:', localStorage.getItem('userType'));

// Interceptor to add token and user-type dynamically
api.interceptors.request.use(config => {
    const token = localStorage.getItem('userToke');
    const userType = localStorage.getItem('userType') === "Admin" ? 'Admin' : 'Merchant';

    if (token) {
        config.headers['token'] = token;
    }
    config.headers['user-type'] = userType;

    // Log the headers to verify
    console.log('Request headers:', config.headers);

    return config;
}, error => {
    return Promise.reject(error);
});

// Define your common API function
const commonApi = {
    get: (url, params = {}) => {
        return api.get(url, { params });
    },
    post: (url, data = {}) => {
        return api.post(url, data);
    },
};
export default commonApi;
